import { Autocomplete, Button, ClickAwayListener, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import { config, S3} from 'aws-sdk';
import { ServerConfig } from "../../connectors/Config";
import moment from "moment";
import { Buffer } from "buffer";
import { LoadingButton } from "@mui/lab";
import { useSnackBar } from "../../views/providers/consumers/useSnackBar";
import store, { setFieldState } from "../../views/store/formStore";
import FilterHelper from '../../views/controllers/FilterHelper';
import { useSelector } from "react-redux";
import GzipHelper from "../../views/controllers/GzipHelper";
import { AuthHelper } from "../../views/controllers/AuthHelper";


const RemoteSelect = (props) => {
    const [choices, setChoices] = React.useState([]);
    const values = useSelector(state => state.fieldsData);
    const course = useSelector(state => state.course);
    const fetchAsync = async() => {
        let endpoint = props.endpoint.includes('{id}') ? props.endpoint.replace('{id}', (await AuthHelper.getUserFromIdToken(localStorage.getItem('cruddy-apiKey'))).Username) : props.endpoint;
        endpoint = endpoint.includes('{course}') ? endpoint.replace('{course}', values.course) : endpoint;
        endpoint = endpoint.includes('{courseId}') ? endpoint.replace('{courseId}', values.school_course) : endpoint;
        
        console.log('VALORE REDUX', values);
        const res = await axios.get(endpoint);
        let result = res.data
        if(result.length > 0){
            setChoices([...result]);
        }
    }
    React.useEffect(() => {
        fetchAsync();
    }, [])
    
    return (
        choices.length > 0 && <TextField select fullWidth {...props} variant="outlined" 
        InputLabelProps={{
            sx: {
                color: "#444",
              }
        }}
        InputProps={{sx: {
            border: '1px solid #eee',
            outlineColor: 'wheat',
            color: '#000',
            fontWeight: 'bold'
        }}}
        inputProps={{style: {color: '#000'}}} 
        style={{color: '#000'}}
        type={props.secret ? 'password' : 'text'} 
        sx={{color: '#000', marginBottom: 1}}>
            {choices.map(x => 
                <MenuItem value={x.name ? x.name : x.id}>{x.name ? x.name : x['nome corso']}</MenuItem>)}
            
        </TextField>
    )
}

const CommercialActionSelect = (props) => {
    
    const values = useSelector(state => state.fieldsData);

    const committenti = {
        IREN: [
            "SWITCH",
            "SWITCH CON VOLTURA LUCE",
            "SUBENTRO"
        ],
        SORGENIA: [
            "SWITCH",
            "SWITCH CON VOLTURA",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
            "POSA CONTATORE"
        ],
        LOOMEN: [
            "SWITCH",
            "SWITCH CON VOLTURA",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
            "POSA CONTATORE"
        ],
        UNION: [
            "SWITCH",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
        ],
        DUFERCO: [
            "SWITCH",
            "SWITCH CON VOLTURA",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
        ],
        "ENEL COMPARATORE": [
            "SWITCH",
            "SWITCH CON VOLTURA LUCE",
            "SUBENTRO"
        ],
        "ENI COMPARATORE": [
            "SWITCH"
        ],
    };

    
    let group = committenti[values['Committente'].toUpperCase()] ? committenti[values['Committente'].toUpperCase()] : [
        "SWITCH",
        "SUBENTRO",
        "NUOVA ATTIVAZIONE",
    ];
    
    console.log(group);
    return (
        group && <TextField select fullWidth {...props} variant="outlined" 
        InputLabelProps={{
            sx: {
                color: "#444",
              }
        }}
        InputProps={{sx: {
            border: '1px solid #eee',
            outlineColor: 'wheat',
            color: '#000',
            fontWeight: 'bold',
        }}}
        inputProps={{style: {color: '#000'}}} 
        style={{color: '#000'}} 
        label="Azione Commerciale"
        onChange={(e) => {
            store.dispatch(setFieldState({name: "Azione Commerciale", value: e.target.value}))
        }}
        
        sx={{color: '#000', marginBottom: 1, marginTop: 2}}>
            {group.map(x => <MenuItem value={x}>{x}</MenuItem>)}
            {/* {choices.map(x => 
                <MenuItem value={x.name}>{x.name}</MenuItem>)} */}
            
        </TextField>
    )
}

const FormInput = (props) => <TextField fullWidth {...props} variant="outlined" 
                            InputLabelProps={{
                                sx: {
                                    color: "#444",
                                  }
                            }}
                            InputProps={{sx: {
                                border: '1px solid #eee',
                                outlineColor: 'wheat'
                            }}}
                            inputProps={{style: {color: '#000'}}} 
                            style={{color: '#000'}} 
                            sx={{color: '#000', marginBottom: 1}} 
                            ref={props.ref}
                            />
const FormInputArea = (props) => <TextField fullWidth multiline minRows={4} {...props} variant="outlined" 
                                InputLabelProps={{
                                    sx: {
                                        color: "#444",
                                        }
                                }}
                                InputProps={{sx: {
                                    border: '1px solid #eee',
                                    outlineColor: 'wheat'
                                }}}
                                inputProps={{style: {color: '#000'}}} 
                                style={{color: '#000'}} 
                                sx={{color: '#000', marginBottom: 1}} 
                                ref={props.ref}
                            />
const FormSelect = (props) => (<FormControl fullWidth>
    <TextField select fullWidth {...props} variant="outlined" 
                            InputLabelProps={{
                                sx: {
                                    color: "#444",
                                  }
                            }}
                            InputProps={{sx: {
                                border: '1px solid #eee',
                                outlineColor: 'wheat',
                                color: '#000',
                                fontWeight: 'bold'
                            }}}
                            inputProps={{style: {color: '#000'}}} 
                            style={{color: '#000'}} 
                            sx={{color: '#000', marginBottom: 1}}>
        {props.choices && props.choices.map((choice, idx) => {
            if(props.labels){
                return (
                    <MenuItem value={choice}>{props.labels[idx]}</MenuItem>
                )
            }else {
                return (
                    <MenuItem value={choice}>{choice}</MenuItem>
                ) 
            }
        })}
    </TextField>
    {/* <Select label={props.label} >
       
    </Select> */}
</FormControl>)


const FormAutoComplete = (props) => {
    return (
    <Autocomplete
        disablePortal
        id={`autocomplete-${props.label}`}
        options={[...new Set(props.choices.map((choice) => ({label: choice[props.extractor]})))]}
        renderInput={(params) => <div ref={params.InputProps.ref}>
            <TextField fullWidth {...params.inputProps} 
                label={props.label}
                variant="outlined" 
                InputLabelProps={{
                    sx: {
                        color: "#444",
                    }
                }}
                InputProps={{sx: {
                    border: '1px solid #eee',
                    outlineColor: 'wheat',
                    color: '#000',
                    fontWeight: 'bold'
                }}}
                inputProps={{style: {color: '#000'}}} 
                style={{color: '#000'}} 
                sx={{color: '#000'}}
            />
        </div>} 
    />
        
)}

const FormDatePicker = (props) => {
    const [open, setOpen] = useState(false);
    const inputRef = useRef();
    const values = useSelector(state => state.fieldsData);

    console.log('NOME FORM INPUT', values);

    useEffect(() => {
        setOpen(false);
    }, [values])
    return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={props.label}
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                            props.onChange(e);
                            setOpen(false);
                        }}
                        open={open}
                        renderInput={(params) => (<TextField 
                        {...params.inputProps} 
                        ref={inputRef} 
                        fullWidth {...params}
                        onClick={() => {
                            const el = document.querySelectorAll('.MuiCalendarPicker-root');
                            el.forEach(e => e.remove());
                            setOpen(true);
                        }}
                        onDoubleClick={() => {
                            setOpen(true);
                        }}
                        variant="outlined" 
                        InputLabelProps={{
                            sx: {
                                color: "#444",
                            }
                        }}
                        InputProps={{sx: {
                            border: '1px solid #eee',
                            outlineColor: 'wheat',
                            color: '#000',
                            fontWeight: 'bold'
                        }}}
                        inputProps={{style: {color: '#000'}}} 
                        style={{color: '#000'}} 
                        sx={{color: '#000'}}
                        value={moment(values[props.fieldName]).format('DD/MM/YYYY')}

                            />)} 
                    />
                </LocalizationProvider>
    
    )
}

const fileConverterBase64 = (file) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            res(reader.result)
        }
        reader.onerror = (err) => {
            rej(err)
        }
    })
}
const AutoSelectProduct = (props) => {
    const [options, setOptions] = useState([]);
    
    const [values, setValues] = useState([]);

   
    const getData = async() => {
        const res = await (await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/certificates')).json();
        setOptions([...res]);
    }

    useEffect(() => {
        getData();
    }, []);

    return <Autocomplete multiple options={options} sx={{marginBottom: 1}} getOptionLabel={(option) => option.name} onChange={(e, newValue) => {
        
        store.dispatch(setFieldState({name: 'Certificati', value: newValue}))

    }} 
    renderInput={(params) => (
        <TextField
          {...params}
          label={"Certificati"}
          variant="outlined" 
        />
      )}/>
}

const FileUploaderS3 = (props) => {
    const { addAlert } = useSnackBar();

    config.update({
        Region: ServerConfig.AWS.s3Bucket.Region, 
        credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
    const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
        accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
        secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
    }});
    const fileRef = useRef();

    const [loading, setLoading] = useState(false);

    return (<>
    <LoadingButton loading={loading} onClick={() => fileRef.current?.click()} variant="contained" color="secondary" sx={{mb:2, mt: 2}}>{loading ? 'Caricamento...' :props.label}</LoadingButton>
      <input type="file" ref={fileRef} hidden onChange={async (e) => {
        setLoading(true);
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.contract-file.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            const res = await s3.putObject(params).promise();
            
            store.dispatch(setFieldState({name: props.fieldName, value: `https://mase-all.s3.eu-west-1.amazonaws.com/${fileName}`}))
            addAlert({
                message: 'Caricamento file completato',
                severity: 'success'
            })
            setLoading(false);
        }catch(ex){

            addAlert({
                message: 'Errore server',
                severity: 'error'
            })
        }
      }}/>
    
    </>
    )
}
const FormSwitcher = (props) => {
    const values = useSelector(state => state.fieldsData);
    let exceptions = props.exceptions?.filter(x => x.committente?.toUpperCase() === values['Committente']?.toUpperCase());
    exceptions = exceptions?.length > 0 ? exceptions[0].only : null;
    return(<FormControl key={props.fieldName} >
        <FormLabel id="demo-radio-buttons-group-label" sx={{color: '#222'}}>{props.label}</FormLabel>
            <RadioGroup
            name="radio-buttons-group"
            onChange={props.onChange}
            row
            >
                
                {exceptions ? props.subforms?.filter(x => exceptions.includes(x.label)).map(form => (
                    <FormControlLabel sx={{
                        color: '#222'
                    }} value={form.label} control={<Radio formtocall={form.form} />} label={form.label} ciao="true" />
                )) : props.subforms?.map(form => (
                    <FormControlLabel sx={{
                        color: '#222'
                    }} value={form.label} control={<Radio formtocall={form.form} />} label={form.label} ciao="true" />
                ))}
            </RadioGroup>
        </FormControl>)
    
}
const switchElement = (props, value, setValue, ref) => {
    switch(props.type){
        case "string": return <FormInput  key={props.fieldName} {...props} ref={ref} />
        case "password": return <FormInput type="password" key={props.fieldName} {...props} ref={ref} />
        case "enum": return <FormSelect key={props.fieldName}  {...props} />
        case "autocomplete": return <FormAutoComplete key={props.fieldName}  {...props} />
        case "date": return <FormDatePicker key={props.fieldName}  {...props}/>
        case "number": return <FormInput key={props.fieldName}  {...props} type="number" />
        case "form_switch": return (<FormSwitcher {...props} />)
        case "file-upload-s3": return (<FileUploaderS3 {...props} />)
        case "remote_select": return (<RemoteSelect endpoint={props.endpoint} {...props}/>)
        case "checkbox": return (  <FormControlLabel control={<Checkbox />} {...props} sx={{color: '#000'}}/>)
        case "azione_commerciale": return (<CommercialActionSelect />)
        case 'autoselect': return (<AutoSelectProduct />)
        case 'textarea': return(<FormInputArea {...props} ref={ref} key={props.fieldName} />)
        default: return null
    }
}


const FormHandler = (props) => {
    const [value, setValue] = useState("");

    return switchElement(props, value, setValue);
}
export { FormHandler}